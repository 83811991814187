<template>
  <div class="staffSet">
    <div class="staffSet-right">
      <div class="staffSet-right-cont">
        <div class="staffSet-right-cont-head">
          <div class="staffSet-right-cont-head-list" v-if="0">
            <span style="width:120px;">选择工种：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="staffSet-right-cont-head-list" style="width:400px;">
            <!-- <span style="width:120px;">搜索：</span> -->
            <el-input
              placeholder="请输入搜索关键字"
              v-model="input"
              clearable
              class="radius"
              style="margin-right:50px;width:100%;"
              @input="inputs"
              @clear="clears"
            >
            </el-input>
          </div>

          <div
            class="staffSet-right-cont-head-list  staffSet-right-cont-head-list1"
          >
            职称：
            <el-select
              v-model="worktype_id"
              placeholder="请选择职称"
              style="width:120px;margin-right:38px"
            >
              <el-option
                v-for="item in options_s"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              style="width:100px"
              icon="el-icon-search"
              @click="searchs"
              >查询</el-button
            >
          </div>

          <div
            class="staffSet-right-cont-head-list  staffSet-right-cont-head-list1"
          >
            <el-button
              type="danger"
              style="width:120px"
              plain
              icon="el-icon-plus"
              @click="edit(1)"
              >添加员工</el-button
            >
          </div>
        </div>
        <el-table
          :data="userSeachData"
          :border="$table('border')"
          :stripe="$table('stripe')"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <el-table-column
            v-if="false"
            prop="shopName"
            label="门店名称"
            :width="$table('width')"
          >
          </el-table-column>
          <el-table-column prop="workID" label="序号" width="80">
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="photo" label="头像">
            <template slot-scope="scope">
              <div class="staffSet-photo">
                <img
                  :src="scope.row.photo"
                  :alt="scope.row.name"
                  v-if="scope.row.photo"
                />
                <img
                  src="../../assets/none.png"
                  :alt="scope.row.name"
                  v-if="!scope.row.photo"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="sex" label="性别"></el-table-column>
          <el-table-column prop="phone" label="手机号码"></el-table-column>
          <el-table-column prop="dep" label="部门">
            <template slot-scope="scope">{{
              scope.row.dep ? scope.row.dep.name : "未添加部门"
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="role_id" label="角色">
            <template slot-scope="scope">{{
              getworktype_name(scope.row.role_id, options3) || "未设置角色"
            }}</template>
          </el-table-column> -->
          <el-table-column prop="worktype_id" label="职位">
            <template slot-scope="scope">{{
              getworktype_name(scope.row.worktype_id, options) || "未设置职位"
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="power" label="权限">
            
          </el-table-column> -->
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">{{
              scope.row.status - 0 ? "在职" : "离职"
            }}</template>
          </el-table-column>
          <el-table-column label="编辑" fixed="right">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                >
                <!-- <el-button type="danger" plain size="small" @click="del(scope)"
                  >删除</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="staffSet-right-cont-page" v-if="false">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[30, 50, 80, 100]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="100"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      :title="type ? '新增资料' : '修改资料'"
      :visible.sync="dialogVisible"
      width="1200px"
    >
      <div class="staffSet-edit" style="min-height:100%;" v-if="0">
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item">
            <span>员工编号：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.workID"
              clearable
            >
            </el-input>
          </div>
          <div class="staffSet-edit-list-item">
            <span>员工姓名：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="staffSet-edit-list-item">
            <span>部门：</span>
            <el-select
              v-model="formLabelAlign.dep_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options1"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item">
            <span>员工性别：</span>
            <el-select
              v-model="formLabelAlign.sex"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options2"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <div class="staffSet-edit-list-item">
            <span>工种：</span>
            <el-select
              v-model="formLabelAlign.worktype_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="staffSet-edit-list-item">
            <span>角色：</span>
            <el-select
              v-model="formLabelAlign.role_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.role_id"
                :label="item.name"
                :value="item.role_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="staffSet-edit-list" v-if="type">
          <div class="staffSet-edit-list-item" v-if="0">
            <span>归属门店：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.shopName"
              clearable
            >
            </el-input>
          </div>
          <div class="staffSet-edit-list-item">
            <span>手机号码：</span>
            <el-input
              placeholder="请输入手机号码"
              v-model="formLabelAlign.phone"
              clearable
              maxlength="11"
            >
            </el-input>
          </div>
          <div class="staffSet-edit-list-item">
            <!-- <span style="margin-left:33px;">手机验证码：</span> -->
            <el-input
              placeholder="请输入验证码"
              v-model="formLabelAlign.vcode"
              clearable
              style="margin:0 10px;width:35%;"
            >
            </el-input>
            <el-button type="primary" @click="getNumber(formLabelAlign.phone)">
              <span v-if="status">点击获取验证码</span>
              <span v-if="!status">请{{ num }}秒后重新获取</span>
            </el-button>
          </div>
          <div class="staffSet-edit-list-item"></div>
        </div>
        <div class="staffSet-edit-list" v-if="0">
          <div class="staffSet-edit-list-item">
            <span>员工权限：</span>
            <el-select
              v-model="formLabelAlign.power"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options1"
                :key="item.name"
                :label="item.id"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div class="staffSet-edit-list-item">
            <span>手机号码：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.phone"
              clearable
              maxlength="11"
            >
            </el-input>
          </div>
          <div class="staffSet-edit-list-item">
            <span>身份证号码：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.card"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="staffSet-edit-list">
          <span style="margin-left:10px;">员工头像：</span>

          <el-image
            v-if="formLabelAlign.photo"
            style="width: 148px; height: 148px;margin-right:50px;margin-left:10px;"
            :src="formLabelAlign.photo"
            :preview-src-list="[formLabelAlign.photo]"
          >
          </el-image>

          <el-upload
            action="/api/app/meiye/upload/shop_photo"
            class="avatar-uploader"
            :show-file-list="false"
            :http-request="update"
            style="margin-left:10px;"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item">
            <span style="margin-left:-10px;">入职日期：</span>
            <el-date-picker
              v-model="formLabelAlign.work_date"
              type="date"
              placeholder="选择日期"
              style="margin-left:-20px;"
            >
            </el-date-picker>
          </div>
          <div class="staffSet-edit-list-item">
            <span style="margin-left:-15px;">状态：</span>
            <el-radio v-model="formLabelAlign.status" label="1">在职</el-radio>
            <el-radio v-model="formLabelAlign.status" label="0">离职</el-radio>
          </div>
          <div class="staffSet-edit-list-item" v-if="0">
            <span style="margin-left:-15px;">小程序预约：</span>
            <el-radio v-model="formLabelAlign.wxApp" label="1">可以</el-radio>
            <el-radio v-model="formLabelAlign.wxApp" label="0">不可以</el-radio>
          </div>
        </div>
        <div class="staffSet-edit-list" v-if="0">
          <span style="margin-left:10px;">员工作品：</span>

          <div v-if="formLabelAlign.works">
            <el-image
              v-for="(item, index) in formLabelAlign.works"
              :key="index"
              style="width: 100px; height: 100px;margin-right:10px;"
              :src="item"
              :preview-src-list="formLabelAlign.works"
            >
            </el-image>
          </div>

          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            style="margin-left:15px;"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="staffSet-edit-list" v-if="0">
          <div class="staffSet-edit-list-item" style="width:33.33%">
            <span>员工登录密码</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.password"
              clearable
            ></el-input>
          </div>
          <div class="staffSet-edit-list-item" style="width:33.33%">
            <span>员工登录密码</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.passwords"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item" style="margin-left:-15px;">
            <span>员工备注</span>
            <el-input
              type="textarea"
              style="margin-left:-15px;width:30%;"
              placeholder="请输入内容"
              v-model="formLabelAlign.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>

      <div class="staffSet-edit">
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-img">
            <el-image
              v-if="formLabelAlign.photo"
              style="width: 160px; height: 160px;"
              :src="formLabelAlign.photo"
              :preview-src-list="[formLabelAlign.photo]"
            >
            </el-image>
            <el-image
              v-if="!formLabelAlign.photo"
              style="width: 160px; height: 160px;"
              :src="require('../../assets/none.png')"
              :preview-src-list="[require('../../assets/none.png')]"
            >
            </el-image>

            <el-upload
              action="/api/app/meiye/upload/shop_photo"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="update"
            >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="type"
                >上传头像</el-button
              >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="!type"
                >更换头像</el-button
              >
              <div slot="tip" class="el-upload__tip">
                <p>建议上传正方形尺寸大小</p>
                <p>小于500kb，200x200像素最佳</p>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item">
            <span>员工编号：</span>
            <div class="staffSet-edit-list-item-input">
              <el-input
                placeholder="请输入员工编号"
                v-model="formLabelAlign.workID"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="staffSet-edit-list-item">
            <span>员工姓名：</span>
            <div class="staffSet-edit-list-item-input">
              <el-input
                placeholder="请输入员工姓名"
                v-model="formLabelAlign.name"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="staffSet-edit-list-item">
            <span>员工性别：</span>
            <div class="staffSet-edit-list-item-input">
              <el-select
                v-model="formLabelAlign.sex"
                style="width: 100%;"
                placeholder="请选择员工性别"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="staffSet-edit-list-item">
            <span>员工部门：</span>
            <div class="staffSet-edit-list-item-input">
              <el-select
                v-model="formLabelAlign.dep_id"
                filterable
                style="width: 100%;"
                placeholder="请选择员工部门"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="staffSet-edit-list-item">
            <span>员工职位：</span>
            <div class="staffSet-edit-list-item-input">
              <el-select
                v-model="formLabelAlign.worktype_id"
                filterable
                style="width: 100%;"
                placeholder="请选择员工职位"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="staffSet-edit-list-item">
            <span>员工角色：</span>
            <div class="staffSet-edit-list-item-input">
              <el-select
                v-model="formLabelAlign.role_id"
                filterable
                style="width: 100%;"
                placeholder="请选择员工角色"
              >
                <el-option
                  v-for="item in options3"
                  :key="item.role_id"
                  :label="item.name"
                  :value="item.role_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="staffSet-edit-list">
          <div class="staffSet-edit-list-item">
            <span>手机号码：</span>
            <div class="staffSet-edit-list-item-input">
              <el-input
                placeholder="请输入手机号码"
                v-model="formLabelAlign.phone"
                clearable
                maxlength="11"
              >
              </el-input>
              <!-- <el-input
                placeholder="请输入手机号码"
                :value="formLabelAlign.phone"
                disabled
                v-if="!type"
              >
              </el-input> -->
            </div>
          </div>
          <!-- 取消验证码 2020/01/07 -->
          <!-- <div class="staffSet-edit-list-item" v-if="type">
            <span>验证码：</span>
            <div class="staffSet-edit-list-item-input">
              <el-input
                placeholder="请输入验证码"
                v-model="formLabelAlign.vcode"
                clearable
                style="margin-left:8px;width:155px;"
              >
              </el-input>
              <el-button
                type="primary"
                style="margin-left:5px;width:150px;"
                @click="getNumber(formLabelAlign.phone)"
              >
                <span v-if="status">点击获取验证码</span>
                <span v-if="!status">请{{ num }}秒后重新获取</span>
              </el-button>
            </div>
          </div> -->
          <div class="staffSet-edit-list-item">
            <span>岗位状态：</span>
            <div class="staffSet-edit-list-item-input">
              <el-radio v-model="formLabelAlign.status" label="1"
                >在职</el-radio
              >
              <el-radio v-model="formLabelAlign.status" label="0"
                >离职</el-radio
              >
            </div>
          </div>
          <div class="staffSet-edit-list-item">
            <span>入职日期：</span>
            <div class="staffSet-edit-list-item-input">
              <el-date-picker
                v-model="formLabelAlign.work_date"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                style="width:100%;"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="staffSet-edit-list-item" style="height:200px;">
            <span>员工备注：</span>
            <div class="staffSet-edit-list-item-input" style="height:200px;">
              <el-input
                type="textarea"
                style="width:100%;"
                placeholder="请输入内容"
                v-model="formLabelAlign.remark"
                maxlength="300"
                show-word-limit
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cencels">取 消</el-button>
        <el-button v-if="type" type="primary" @click="saves(1)"
          >添加员工</el-button
        >
        <el-button v-if="!type" type="primary" @click="saves(0)"
          >保存修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      userData: [],
      userSeachData: [],
      userDataIndex: "",
      formLabelAlign: {},
      options: [],
      options_s: [{ id: 0, name: "全部", sort: 9 }], //搜索职称
      options1: [],
      options2: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      options3: [],
      currentPage4: 1,
      value: "",
      input: "",
      radio: "",
      num: 60,
      status: true,
      code_status: false,
      worktype_id: 0, //工种
    };
  },
  methods: {
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.searchs();
      }
    },
    getNumber(obj) {
      let _this = this;
      let time = null;
      clearInterval(time);
      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
          obj
        )
      ) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      if (this.num == 60 && this.status) {
        _this.status = !_this.status;

        this.$axios({
          href: "/api/app/meiye/sms/staff_vcode_send",
          data: { phone: obj },
        }).then(() => {
          this.$message({
            message: "发送成功~",
            type: "success",
          });
          _this.code_status = !_this.code_status;
          // console.log(res);
        });

        time = setInterval(() => {
          if (_this.num < 1) {
            _this.num = 60;
            _this.status = !_this.status;
            clearInterval(time);
          } else {
            _this.num--;
          }
        }, 1000);
      }
    },
    clears() {
      this.searchs();
    },
    // inputs() {
    //   if (!this.input) {
    //     this.clears();
    //     return false;
    //   }
    //   this.searchs();
    // },
    searchs() {
      // console.log(this.worktype_id+1)
      // let d = this.worktype_id+1;

      if (this.worktype_id == 0) {
        this.getList();
        return false;
      }

      if (!this.input && this.worktype_id == 0) {
        this.$message.error("请输入搜索关键字...");
        return false;
      }
      this.getList();
    },
    tabType(index) {
      this.tabIndex = index;
    },
    getworktype_name(id, obj) {
      let d = obj;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (d[i].id == id || d[i].role_id == id) {
          return d[i].name;
        }
      }
    },
    getworktype() {
      this.$axios({
        href: "/api/app/meiye/worktype/store",
      }).then((res) => {
        console.log(res);
        this.options = res.data;
        this.options_s = this.options_s.concat(res.data);
      });

      this.$axios({
        href: "/api/app/meiye/shopdep/store",
      }).then((res) => {
        console.log(res);
        this.options1 = res.data;
      });
    },
    /* 获取数据 */
    getList() {
      let ids = this.worktype_id ? this.worktype_id : null;
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { worktype_id: ids, keyword: this.input },
      }).then((res) => {
        console.log(res);
        let d = res.data;
        let len = d.length;
        for (let i = 0; i < len; i++) {
          res.data[i].status = res.data[i].status ? "1" : "0";
        }
        this.userData = res.data;
        this.userSeachData = res.data;
      });
    },
    /* 添加员工 */
    addList() {
      this.$axios({
        href: "/api/app/meiye/user/add",
        data: this.formLabelAlign,
      }).then((res) => {
        console.log(res);
        this.cencels();
        this.getList();
        this.code_status = false;
        this.$message({
          type: "success",
          message: "添加成功!",
        });
      });
    },
    /* 添加更新 */
    edit(index, obj) {
      // console.log(obj);

      this.type = index;
      if (!index) {
        this.userDataIndex = obj.$index;
        this.formLabelAlign = JSON.parse(
          JSON.stringify(this.userSeachData[obj.$index])
        );
      }
      if (index) {
        this.userDataIndex = -1;
        this.formLabelAlign = {
          workID: "",
          name: "",
          phone: "",
          photo: "",
          sex: "",
          role_id: "", //1
          worktype_id: "",
          work_date: "",
          remark: "",
          vcode: "", //324560
          p_staff_id: "",
          dep_id: "",
          status: "1",
        };
      }
      this.dialogVisible = !this.dialogVisible;
    },
    updateList() {
      this.$axios({
        href: "/api/app/meiye/user/edit",
        data: this.formLabelAlign,
      }).then((res) => {
        console.log(res);
        this.cencels();
        this.getList();
        this.code_status = false;
        this.$message({
          type: "success",
          message: "修改成功!",
        });
      });
    },
    del(obj) {
      console.log(obj);
      this.$confirm("此操作将永久删除该资料, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cencels() {
      this.dialogVisible = !this.dialogVisible;
    },
    saves(obj) {
      if (!/^[A-Za-z0-9]{2,5}$/.test(this.formLabelAlign.workID)) {
        this.$message.error(
          "员工编号请输入字母+数字 长度为2-5个字符，不能含有符号"
        );
        return false;
      }
      //  /^(?=.*[a-zA-Z])(?=.*\d).+$/
      if (!this.formLabelAlign.name) {
        this.$message.error("请输入正确的姓名");
        return false;
      }

      /* 编辑不修改手机号码，只有添加的时候才需要 */
      /* 2021/01/07取消验证码 */
      if (this.type) {
        if (
          !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
            this.formLabelAlign.phone
          )
        ) {
          this.$message.error("请输入正确的手机号码");
          return false;
        }

        this.formLabelAlign.vcode = 888888;
        // if (!this.formLabelAlign.vcode - 0) {
        //   this.$message.error("请输入6位数字验证码");
        //   return false;
        // }

        // // console.log(this.formLabelAlign.vcode);

        // if (!this.code_status) {
        //   this.$message.error("请获取验证码");
        //   return false;
        // }
      }

      if (obj) {
        this.addList();
        return false;
      }
      this.updateList();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      console.log(file);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      let d = new FormData();
      d.append("image", file.file);
      d.append("token", sessionStorage.getItem("token"));
      this.$axios({
        href: "/api/app/meiye/upload/staff_photo",
        data: d,
      }).then((res) => {
        console.log(res, res.data.data.url);
        if (this.userDataIndex < 0) {
          console.log(11111111111);
          this.formLabelAlign.photo = res.data.data.url;
        } else {
          console.log(2222222222222222);
          this.userSeachData[this.userDataIndex].photo = res.data.data.url;
          this.formLabelAlign.photo = res.data.data.url;
        }
      });
    },
    /* 获取角色 */
    getroles() {
      this.$axios({
        href: "/api/myadmin/shop/roles",
      }).then((res) => {
        console.log(res);
      });
    },
  },
  created() {
    this.getList();
    this.getworktype();
    this.options3 = JSON.parse(sessionStorage.getItem("init")).roles;
    console.log(this.options3);
    // this.getroles();
  },
};
</script>

<style lang="scss" scoped>
.staffSet {
  width: 100%;
  height: 100%;
  display: flex;
  &-photo {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-head {
        width: 100%;
        height: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        // background: red;
        &-list {
          // max-width: 33.33%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-list:nth-of-type(1) {
          width: 400px;
        }
        &-list:nth-of-type(2) {
          width: 339px;
          span {
            display: inline-block;
            width: 100px;
          }
          // margin-right: 50px;
        }
        &-list:nth-of-type(3) {
          // background: red;
          position: absolute;
          top: 0px;
          right: 10px;
          width: 120px;
          button {
            width: 120px;
          }
        }
      }
      &-page {
        width: 100%;
        height: 60px;
        margin-top: 20px;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 300px;
    display: flex;
    // background: red;
    &-display {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-list {
      //  width: 33.33%;
      box-sizing: border-box;
      min-height: 500px;
      &-item {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        // span:{
        //   display: inline-block;
        //   widt
        // }
        &-input {
          width: 320px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    &-list:nth-of-type(1) {
      padding: 24px;
      width: 224px;
      margin: 0 28.5px;
      // background: pink;
      min-height: 360px;
    }

    &-list:nth-of-type(2) {
      padding-top: 24px;
      min-width: 406px;
      min-height: 360px;
      margin-right: 57.5px;
      // background: blue;
    }
    &-list:nth-of-type(3) {
      padding-top: 24px;
      min-width: 415px;
      min-height: 360px;
      // background: pink;
    }
  }
}
</style>

<style moudle>
.staffSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.staffSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.staffSet-right-cont-head {
  transition: all 0.5s;
}

/* .select {
  width: 100%;
} */

.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
/* .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
}
.avatar-uploader:hover {
  border: 1px dashed #409eff;
} */
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radius .el-input__inner {
  border-radius: 20px;
}

.staffSet-edit-list-img .el-upload__tip p {
  padding: 0;
  margin: 0;
  color: #bbbbbb;
  font-size: 14px;
}

.staffSet-edit-list-item-input .el-textarea .el-textarea__inner {
  height: 200px;
}
</style>
